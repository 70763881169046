<template>
    <fullscreen-layout>
        <div class="max-w-md w-full space-y-8">
            <div>
                <img class="mx-auto h-12 w-auto" src="https://foodex.menu/Portals/_default/Skins/Foodex-v2/icons/foodex-logo.svg" alt="foodex" />
            </div>
            <form class="mt-8 space-y-6" action="#" method="POST">
                <input type="hidden" name="remember" value="true" />
                <div class="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label for="email-address" class="sr-only">Email address</label>
                        <input id="email-address" name="email" type="email" v-model="email" autocomplete="email" required="" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address" />
                    </div>
                    <div>
                        <label for="password" class="sr-only">Password</label>
                        <input id="password" name="password" type="password" autocomplete="current-password" v-model="password" required="" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password" />
                    </div>
                </div>

                <div>
                    <button v-if="!loading" @click="doLogin()" type="button" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-peach hover:bg-dark-peach focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-peach">
                        <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-peach-300 group-hover:text-peach-200" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                            </svg>
                        </span>
                        Sign in
                    </button>
                    <button v-else type="button" disabled class="group relative w-full flex justify-center items-center h-9 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-peach hover:bg-dark-peach focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-peach">
                        <i class="fas fa-circle-notch fa-spin"></i>
                    </button>
                </div>

                <div class="text-peach h-6">
                    {{error}}
                </div>

                
            </form>
        </div>
    </fullscreen-layout>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import FullscreenLayout from '../layouts/FullscreenLayout.vue'
import { useRouter } from 'vue-router';
import usefdxAuth from '../hooks/fdxAuth'

import fAPI from '../integration/foodexAPI'
import { AxiosError, AxiosResponse } from 'axios';

export default defineComponent({
    name: 'Login',
    components: {
        FullscreenLayout
    },
    setup() {
        const router = useRouter();
        const error = ref('')
        const email = ref('')
        const password = ref('')
        const loading = ref(false)
        const { login:authLogin, } = usefdxAuth();

        const doLogin = async() => {
            error.value = ''
            console.log(email.value, password.value);
            if (email.value && password.value)
            {
                loading.value = true
                fAPI
                .login(email.value, password.value)
                .then((response: AxiosResponse)=>{
                    console.log('login success!')
                    authLogin(response.data)
                    router.replace('/dashboard')
                })
                .catch((authError: AxiosError) => {
                    console.log('login failed', authError)
                    if (authError && authError.response)
                        error.value = authError.response.status === 401 ? 'Invalid Credentials' : 'System Error';
                })
                .finally(() => loading.value = false)
            }
            else
            {
                error.value = 'Missing Credential'
            }
        }

        return {
            email,
            password,
            error,
            loading,
            doLogin,
        }
    },
})
</script>