
import { defineComponent, ref } from 'vue';
import FullscreenLayout from '../layouts/FullscreenLayout.vue'
import { useRouter } from 'vue-router';
import usefdxAuth from '../hooks/fdxAuth'

import fAPI from '../integration/foodexAPI'
import { AxiosError, AxiosResponse } from 'axios';

export default defineComponent({
    name: 'Login',
    components: {
        FullscreenLayout
    },
    setup() {
        const router = useRouter();
        const error = ref('')
        const email = ref('')
        const password = ref('')
        const loading = ref(false)
        const { login:authLogin, } = usefdxAuth();

        const doLogin = async() => {
            error.value = ''
            console.log(email.value, password.value);
            if (email.value && password.value)
            {
                loading.value = true
                fAPI
                .login(email.value, password.value)
                .then((response: AxiosResponse)=>{
                    console.log('login success!')
                    authLogin(response.data)
                    router.replace('/dashboard')
                })
                .catch((authError: AxiosError) => {
                    console.log('login failed', authError)
                    if (authError && authError.response)
                        error.value = authError.response.status === 401 ? 'Invalid Credentials' : 'System Error';
                })
                .finally(() => loading.value = false)
            }
            else
            {
                error.value = 'Missing Credential'
            }
        }

        return {
            email,
            password,
            error,
            loading,
            doLogin,
        }
    },
})
