<template>
    <ion-page>
        <ion-content :fullscreen="true">
            
            <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <slot />
            </div>
            
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonPage, IonContent, } from '@ionic/vue';
export default defineComponent({
    name: 'Fullscreen Layout',
    components: {
        IonContent,
        IonPage,
    },
    setup() {
        return {

        }
    },
})
</script>